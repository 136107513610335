import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hiddenField", "displayField", "datalist" ]
  
  connect() {
    this.updateDisplayFieldFromHiddenField()
  }
  
  updateDisplayFieldFromHiddenField() {
    const hiddenFieldValue = this.hiddenFieldTarget.value
    const displayValue = this.getDisplayValueForHiddenFieldValue(hiddenFieldValue)
    
    if (displayValue) {
      this.displayFieldTarget.value = displayValue
    }
  }
  
  update() {
    const newDisplayValue = this.displayFieldTarget.value
    const hiddenFieldValue = this.getHiddenFieldValueForDisplayValue(newDisplayValue)
    
    if (hiddenFieldValue != null) {
      this.hiddenFieldTarget.value = hiddenFieldValue
    }
  }
  
  getDisplayValueForHiddenFieldValue(someValue) {
    const childNodes = this.getDatalistChildNodes()
    const matchNode = this.getNodeWithHiddenFieldValueFromNodes(someValue, childNodes)
    const displayValue = matchNode.value
    return displayValue
  }
  
  getDatalistChildNodes() {
    return this.datalistTarget.childNodes
  }
  
  getHiddenFieldValueForDisplayValue(someValue) {
    const childNodes = this.getDatalistChildNodes()
    const matchNode = this.getNodeWithValueFromNodes(someValue, childNodes)
    const hiddenFieldValue = matchNode.dataset.hiddenFieldValue
    return hiddenFieldValue
  }
  
  getNodeWithHiddenFieldValueFromNodes(value, nodes) {
    for (const node of nodes) {
      if (node.dataset?.hiddenFieldValue == value) {
        return node
      }
    }
    
    return null
  }
  
  getNodeWithValueFromNodes(value, nodes) {
    for (const node of nodes) {
      if (node.value == value) {
        return node
      }
    }
    
    return null
  }
}